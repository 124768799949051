import Layout from 'src/components/Layout'
import styled from 'styled-components'
import { H2 } from 'src/styles/Typography'
import { getAllVerticals } from 'src/utils/contentful'

export default function Custom404({ verticals }) {
  return (
    <Layout verticals={verticals} seo={{ title: '404 - Page Not Found' }}>
      <Main>
        <H2>404 - Page Not Found</H2>
      </Main>
    </Layout>
  )
}

const Main = styled.main`
  margin: 3rem auto;
  text-align: center;
`

export async function getStaticProps() {
  const verticals = await getAllVerticals()

  return {
    props: {
      verticals,
    },
  }
}
